import React from "react"
import ReactGA from "react-ga4"
import Visual from "../Visuals/Visual 13.jpeg"
import commonClasses from "../ArticleStyle.css"
import classes from "./vub-gebouw-c.css"
import FatArrow from "../../../containers/UI/FatArrow.svg"

const News = (props) => {

  return (
    <>
      <div className={commonClasses.container}>

        <div className={commonClasses.innerContainer}>
          <h3 className={commonClasses.dateLabel}>Brussels, January 15th 2025</h3>
          <h1>Brussels University adopts smart heating with Allorado</h1>
          
          <div className={classes.visualContainer}>
            <img src={Visual} className={commonClasses.visual} alt="Building C at the University of Brussels in Etterbeek."/>
            <p className={commonClasses.imageCaption} style={{fontSize: "15px"}}>Building C at the VUB, outfitted with 425 smart valves and 200 temperature sensors</p>
          </div>

          <p className={commonClasses.contentParagraph}>With sustainability targets becoming increasingly important, <a href="https://www.vub.be/nl" className={commonClasses.inlineLink} target="_blank" rel="noreferrer">Vrije Universiteit Brussel</a> (VUB) sought an intelligent energy management solution to reduce its CO2 footprint and heating costs. To achieve this, the university partnered with Allorado and <a href="https://www.mcs-nl.com" className={commonClasses.inlineLink} target="_blank" rel="noreferrer">MCS</a> to implement a private LoRaWAN network, retrofitting its existing buildings with IoT-driven smart heating solutions.</p>
          <p className={commonClasses.contentSubtitle}>Intelligent heating room by room</p>
          <p className={commonClasses.contentParagraph}>
            To modernize VUB's heating system, Allorado and MCS deployed a large-scale LoRa network tailored to the university's needs. The project involved the installation of:
            <ul>
              <li><strong>423 MClimate Vicky</strong> thermostatic radiator valves, replacing manual controls to enable remote, automated temperature regulation.</li>
              <li><strong>200 Elsys ERS Lite</strong> sensors, providing real-time insights into temperature, humidity, light, and CO2 levels across building C.</li>
            </ul>
            The LoRa network enables these devices to communicate wirelessly over long distances, making it particularly effective for retrofitting older buildings without the need for extensive cabling.
          </p>


          <p className={commonClasses.contentSubtitle}>Reliable & scalable data transmission</p>
          <p className={commonClasses.contentParagraph}>The implementation required handling significant IoT traffic efficiently. The LoRa sensors generate 120,000 daily data packets, relaying environmental conditions to the Allorado Gateway. In turn, 60,000 control messages are sent back daily to the Vicky radiator valves, optimizing the heating in real time.</p>

          <p className={commonClasses.contentSubtitle}>Optimized network design & integration</p>
          <p className={commonClasses.contentParagraph}>Prior to deployment, Allorado and MCS conducted an extensive site analysis to determine the optimal placement of 9 LoRa forwarders across VUB's six-story buildings. This ensured robust connectivity and reliable data transmission.</p>
          <p className={commonClasses.contentParagraph}>Furthermore, the private LoRa network was seamlessly integrated into VUB's existing Building Management System (BMS) via the BACnet protocol. The Allorado Gateway presents LoRa sensor data as native BACnet objects, enabling effortless monitoring and control within the university's established infrastructure.</p>

          <p className={commonClasses.contentSubtitle}>End-to-end IoT management</p>
          <p className={commonClasses.contentParagraph}>By working closely with MCS, Allorado ensured VUB had a single point of contact for its entire IoT deployment. The system not only delivers immediate energy efficiency improvements but also lays the foundation for further smart building enhancements.</p>
          <p className={commonClasses.contentParagraph}>This project highlights Allorado's expertise in designing and deploying scalable, secure, and highly efficient LoRa solutions, making smart energy management a reality for large institutions.</p>
                 
    
          <div className={commonClasses.callToAction}>
            <h3>Does your building need an upgrade?</h3>
            <div className={commonClasses.orderRow}>
                <button className={commonClasses.orderButton} onClick={() => {
                  ReactGA.event({ category: "Button", action: "News item to contact" })
                  window.location = "mailto:sales@allorado.io?subject=Upgrading my building"
                }}>Get in touch today</button>
              <img src={FatArrow} className={commonClasses.arrow} alt="arrow"/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default News;
